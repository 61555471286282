const config: {
  color: string;
  numberOfBeats: number;
  trackingID: string;
  isMaintenance: boolean;
  messageForMaintenance: string;
} = {
  color: "blue",
  numberOfBeats: 4,
  trackingID: "UA-105303094-8",
  isMaintenance: process.env.REACT_APP_MAINTENANCE === "1",
  messageForMaintenance:
    process.env.REACT_APP_MAINTENANCE_MESSAGE ||
    "Unmixer will be temporarily suspended"
};

export default config;
